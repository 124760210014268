<template>
  <b-alert 
        :variant="variant" 
        dismissible
        :show="dismissCountDown" 
        v-height-fade.appear
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
    >
        <div class="alert-body">
            <strong :class="'text-'+variant">{{ message }}</strong>
        </div>
    </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'

export default {
    components: {
        BAlert
    }, 

    directives: {
        heightFade
    },

    props: {
        message: {
            required: true,
            type: String,
        },
        show: {
            required: true,
            type: Boolean,
        },
        variant: {
            type: String
        }
    },

    data() {
        return {
            dismissSecs: 0,
      		dismissCountDown: 0
        }
    },

    methods: {
		countDownChanged(dismissCountDown) {
            if(dismissCountDown == 0) {
                this.$emit('closedAlert');
            }
			this.dismissCountDown = dismissCountDown
		},	

        showAlert() {
            this.dismissCountDown = 8
        }
    },

    watch: {
        show(value) {
            if(value) { this.showAlert() }
        },
    }
}
</script>

