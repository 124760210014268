<template>
      <b-dropdown
        id="dropdown-colaborador"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Colaborador"
        :variant="variant"
        no-caret
      >
        <b-dropdown-item @click="resolveRoute('empresa-colaborador-list')">Ver colaboradores</b-dropdown-item>
        <b-dropdown-item v-if="canManageColab" @click="resolveRoute('empresa-colaborador-register')">Cadastrar novo</b-dropdown-item>
        <b-dropdown-item @click="resolveRoute('empresa-colaborador-import-list')">Ver importações</b-dropdown-item>
        <b-dropdown-item v-if="canManageColab" @click="resolveRoute('empresa-colaborador-import')">Importar planilha</b-dropdown-item>
      </b-dropdown>

</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BDropdown, BDropdownItem, BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BDropdown,
    BDropdownItem,
    BCardText,
  },
  props: {
    variant: {
      type: String,
      default: '',
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return { 
      canManageColab: false,
    }
  },
  mounted() {
        this.canManageColab = this.$can(actions.GERENCIAR, subjects.COLABORADOR_MENUS_ROTAS)
  },
  methods: {
    resolveRoute(route){
        this.$emit('confirmed', route);
    },
    
  }
}
</script>