<template>
  <div class="content-wrapper">
    <PageHeader :screenName="empresa.nome" :linkItems="linkItems" />
    <main class="card hidden_overflow_tabela">
      <HeaderEmpresa :empresa="empresa" @confirmed="resolveRoute" />
      <section class="p-2">
        <h1>Importação de colaboradores</h1>
        <span
          >Acompanhe a importação dos colaboradores.<br />
          Planilhas de erro ficam disponíveis por apenas 30 dias.
        </span>
      </section>
      <div v-if="loadingData" class="d-flex justify-content-center my-3">
        <div class="spinner-border text-custom-blue" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <hr />
      <div>
        <section class="filters pb-1 px-2" v-if="!loadingData">
          <b-row align-h="between">
            <b-col lg="3" md="4" sm="5" class="col-12 mb-1 mb-sm-0">
              <div class="d-flex justify-center-center align-items-center">
                <span class="mr-1" for="tableSize">Mostrar</span>
                <v-select
                  id="tableSize"
                  v-model="paginationData.defaultSize"
                  @input="updateTableSize($event)"
                  :options="table.optionsSize"
                  :clearable="false"
                />
              </div>
            </b-col>
            <b-col lg="5" md="6" sm="7">
              <div class="d-flex justify-center-center align-items-center">
                <span class="mr-1" for="search-input">Buscar</span>
                <b-form-input
                  id="search-input"
                  v-model="searchTerm"
                  @input="filterImport"
                />
              </div>
            </b-col>
          </b-row>
        </section>
        <section class="list-colaborador-import" v-if="!loadingData">
          <b-table
            id="tableColaboradorImport"
            responsive
            :stickyColumn="true"
            :busy.sync="filtering"
            :fields="table.fields"
            :items="table.items"
            @sort-changed="ordenarColuna"
            no-local-sorting
            :no-sort-reset="true"
          >
            <template #cell(nome)="row">
              <span
                class="font-weight-bold comprimento_maximo_celulas"
                v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
                :title="row.item.nome"
              >
                {{ row.item.nome }}
              </span>
            </template>
            <template #cell(criacao)="row">
              <span class="font-weight-bold comprimento_maximo_celulas">
                {{ formatDate(row.item.data_hora, "DD/MM/YYYY - HH[h]mm") }}
              </span>
            </template>
            <template #cell(situacao)="row">
              <span
                v-if="row.item.situacao == 'Pendente'"
                class="font-weight-bold comprimento_maximo_celulas rounded-pill custom"
                :class="formatString(row.item.situacao)"
              >
                {{ row.item.situacao }}
              </span>
              <span
                v-else
                class="font-weight-bold comprimento_maximo_celulas rounded-pill custom concluido"
              >
                Concluído
              </span>
            </template>
            <template #cell(numero_linhas)="row">
              <span
                class="font-weight-bold comprimento_maximo_celulas"
                v-if="row.item.situacao == 'Pendente'"
              >
                {{ formatDate(row.item.data_hora, "DD/MM/YYYY - HH[h]mm") }}
              </span>
              <span class="font-weight-bold comprimento_maximo_celulas" v-else>
                {{ formatError(row.item.numero_linhas, row.item.erros_count) }}
              </span>
            </template>
            <template #cell(actions)="row">
              <feather-icon
                v-if="
                  row.item.erros_count == 0 || row.item.situacao == 'Pendente'
                "
                id="download-import"
                role="button"
                class="text-secondary not-allowed"
                icon="DownloadIcon"
                size="18"
              />
              <feather-icon
                v-else
                id="download-import"
                role="button"
                class="text-custom-blue"
                icon="DownloadIcon"
                size="18"
                v-b-tooltip.hover.bottomleft="{ variant: 'light' }"
                :title="formatLimitDate(row.item.data_hora)"
                @click.prevent="baixarPlanilha(row.item)"
              />
            </template>
          </b-table>
          <div class="pb-2 px-1">
            <span v-if="withoutImport" class="ml-1"
              >Nenhuma importação encontrada</span
            >
            <b-col sm="12" class="mt-2">
              <CustomPagination
                :paginacao="paginationData"
                @page-cliked="updateCurrentPage"
              />
            </b-col>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import TableSwitch from "@/views/components/custom/table-switch/TableSwitch.vue";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import HeaderEmpresa from "@/views/components/custom/page-header/Header-empresa.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { getIdEmpresa } from "@core/utils/store/getStore";
import { setIdEmpresa } from "@core/utils/store/setStore";

export default {
  title: "Importações de colaboradores",

  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    TableSwitch,
    BCard,
    vSelect,
    CustomPagination,
    PageHeader,
    TimedAlert,
    VuePerfectScrollbar,
    BSpinner,
    HeaderEmpresa,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      linkItems: [
        {
          name: "Empresa",
          routeName: "empresa-colaborador-list",
        },
        {
          name: "Ver empresa",
          active: true,
        },
        {
          name: "Carregando dados...",
          active: true,
        },
      ],
      alert: {
        errorMessage: "",
        show: false,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      timeID: null,
      filtering: false,
      loadingData: true,
      withoutImport: false,
      searchTerm: "",
      today: moment().format("YYYY-MM-DD"),
      table: {
        fields: [
          { key: "nome", label: "PLANILHA", sortable: true, class: "mw-300" },
          {
            key: "criacao",
            label: "DATA E HORA",
            sortable: true,
            class: "mw-150",
          },
          {
            key: "situacao",
            label: "SITUAÇÃO",
            sortable: true,
            class: "mw-150",
          },
          { key: "tipo", label: "TIPO", sortable: false, class: "mw-150" },
          {
            key: "numero_linhas",
            label: "RESULTADO",
            sortable: true,
            class: "mw-150",
          },
          {
            key: "actions",
            label: "AÇÕES",
            sortable: false,
            class: "mw-150 text-center col-1",
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: "id_planilha",
        order: "desc",
      },
      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      empresa: {
        id_empresa: "",
        nome: "",
        gerenciarColaboradores: false
      },
    };
  },
  mounted() {
    if (
      (getIdEmpresa() === null || typeof getIdEmpresa() == "undefined") &&
      typeof this.$route.params.idEmpresa == "undefined"
    ) {
      this.openModalError();
      return;
    }

    if (typeof this.$route.params.idEmpresa != "undefined") {
      if (this.$route.params.idEmpresa !== getIdEmpresa()) {
        setIdEmpresa(this.$route.params.idEmpresa);
      }
    }

    this.$http
      .get(this.$api.getShowAndEditEmpresa(getIdEmpresa()))
      .then(({ data }) => {
        this.empresa.nome = data["cnpj"] ? data["nome_fantasia"] : data["nome"];
        this.empresa.gerenciarColaboradores = data['pode_gerenciar_colaboradores'];
        this.empresa.id_empresa = data.id_empresa;
        this.linkItems[2].name = this.empresa.nome;
        this.loadDataImport();
      });
  },
  methods: {
    loadDataImport(filterData = null) {
      this.paginationData.defaultSize = filterData
        ? filterData.defaultSize
        : 10;

      const parameters = {
        termoPesquisa: filterData ? filterData.searchTerm : "",
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order,
        id_empresa: this.empresa.id_empresa,
      };

      this.filtering = true;

      this.$http
        .get(this.$api.importColaborador(), { params: parameters })
        .then(({ data }) => {
          this.table.items = data.data;
          this.loadingData = false;

          if (data.data == 0) {
            this.withoutImport = true;
          }

          this.filtering = false;
          this.startPagination(data);
        });
    },
    baixarPlanilha(item) {
      this.loadingShow = true;

      (async () => {
        try {
          await this.$http
            .get(this.$api.importColaboradorParameter(item.id_planilha))
            .then((res) => {
              let conteudo = res.data;
              this.$helpers.downloadArchiveCsv(conteudo, item.nome);
              this.loadingShow = false;
            });
        } catch (err) {
          this.$router.push({ name: "error-404-logged" });
        }
      })();
    },
    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },

    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? "desc" : "asc";

      this.filtering = true;

      this.loadDataImport();
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
      this.loadDataImport();
    },

    updateTableSize(event) {
      if (!event) {
        this.paginationData.defaultSize = 10;
      }
      this.filtering = true;

      this.paginationData.currentPage = 1;
      this.loadDataImport();
    },
    filterImport() {
      if (this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
        this.withoutColab = false;

        clearTimeout(this.timeID);
        this.timeID = setTimeout(() => {
          this.filtering = true;
          this.loadDataImport();
        }, 500);
      }
    },
    formatString(string) {
      const formatted = string
        .normalize("NFD")
        .replace(/([\u0300-\u036f]|[^a-zA-Z])/g, "");
      return formatted.toLowerCase();
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push({
          name: route,
          params: {
            empresa: this.empresa,
          },
        });
      }
    },
    formatError(nLinhas, nErros) {
      let msg = "";
      if (nLinhas == nErros) {
        msg = "Erro: " + nErros;
      } else if (nErros == 0) {
        msg = "Sucesso: " + nLinhas;
      } else {
        msg = "Sucesso: " + (nLinhas - nErros) + " | Erros: " + nErros;
      }

      return msg;
    },
    formatLimitDate(date) {
      let limit = moment(date).add(30, "days"),
        diff = limit.diff(moment(), "days"),
        text = diff == 1 ? " dia." : " dias.";

      return "Disponível por " + diff + text;
    },
  },
};
</script>

<style scoped>
.custom {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}
.concluido {
  background-color: #d4e3f2;
  color: #2772c0;
}
.pendente {
  background-color: #fff3e8;
  color: #ff9f43;
}
.not-allowed {
  cursor: not-allowed;
}
</style>