<template>
      <b-dropdown
        id="dropdown-user"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Usuário"
        :variant= "variant"
        no-caret
      >
        <b-dropdown-item @click="resolveRoute('empresa-usuario-list')">Ver usuários</b-dropdown-item>
        <b-dropdown-item @click="resolveRoute('empresa-usuario-register')">Cadastrar novo</b-dropdown-item>
      </b-dropdown>

</template>

<script>
import { BDropdown, BDropdownItem, BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BDropdown,
    BDropdownItem,
    BCardText,
  },
  props: {
    variant: {
      type: String,
      default: '',
    },
  },
  directives: {
    Ripple,
  },
  methods: {
    resolveRoute(route){
        this.$emit('confirmed', route);
    },
    
  }
}
</script>