<template>
   <b-form-checkbox
        :id="'check-ativo-'+description"
        :checked="checked"
        class="custom-control-success"
        name="check-button"
        @change="changedState"
        switch
        :disabled="disabled"
    >
        <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
        </span>
    </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';

export default {
    props: {
        checked: {
            required: true,
            type: Boolean
        },

        description: {
            required: true,
            type: String
        },
        itemId: {
            required: true,
            type: Number
        },
        disabled: {
            required: false,
            type: Boolean
        }
    },

    components: { BFormCheckbox },

    methods: {
        changedState(state) {
            this.$emit('stateModified', {
                'state': state, 
                'itemId': this.$props.itemId
            });
        }
    }
}
</script>

