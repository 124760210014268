<template>
    <section class="shadow">
        <b-row class="position-absolute mt-5 ml-2 posicaoIcone">
            <b-card class="card rounded-2 mx-2 mt-1 logo-empresa d-flex align-items-center">
            <h1>{{ empresa.nome[0] }}</h1>
            </b-card>
            <h1 class="text-white mt-4 comprimento_maximo_celulas">{{ empresa.nome }}</h1>
        </b-row>

        <div class="box-img-bg">
            <img alt="bg" :src="imgBg">
        </div>
        <div class="d-flex justify-content-center mx-2 mt-2 ">
            <b-nav pills class="mb-1 colunaResponsivo posicaoAbas">
                <b-button 
                    :variant="dados.variant"
                    @click="confirmAction(dados.path)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                >Dados da empresa</b-button>

                <b-button 
                    :variant="contato.variant"
                    @click="confirmAction(contato.path)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                > Contato </b-button>

                <b-button 
                    :variant="atendimento.variant"
                    @click="confirmAction(atendimento.path)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                > Atendimento </b-button>

                <DropdownColaborador
                    v-if="canViewColab"
                    :variant="colaborador.variant"
                    @confirmed="confirmAction"
                />

                <DropdownCompanyUser
                    v-if="canViewCompanyUsers"
                    :variant="companyUser.variant"
                    @confirmed="confirmAction"
                />
            </b-nav>
        </div>
    </section>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BCol, BRow, BCard, BNav, BButton, BNavItem } from 'bootstrap-vue'
import DropdownColaborador from '@/views/components/custom/dropdown/Dropdown-colaborador.vue'
import Ripple from 'vue-ripple-directive'
import DropdownCompanyUser from '@/views/components/custom/dropdown/DropdownCompanyUser.vue'

export default {
    components: {
    BCol,
    BRow,
    BCard,
    BNav,
    BNavItem,
    BButton,
    DropdownColaborador,
    DropdownCompanyUser
},
    props: {
        empresa: {
            required: true,
            type: Object,
            default: {
                id_empresa: '',
                nome: '',
                gerenciarColaboradores: false
            }
        },
    },
    directives: {
    Ripple,
    },
    data() {
        return {
            imgBg: require('@/assets/custom-images/bg/bg-empresa.png'),
            colaborador: {
                variant: 'flat-dark'
            },
            companyUser: {
                variant: 'flat-dark'
            },
            dados: {
                variant: 'flat-dark',
                path: 'empresa-show'
            },
            contato: {
                variant: 'flat-dark',
                path: 'empresa-contato-show'
            },
            atendimento: {
                variant: 'flat-dark',
                path: 'empresa-atendimento-show'
            },
            canViewColab: false,
            canViewCompanyUsers: false,
            userCanViewColab: false
        }
    },
    created() {
        let rotasColaborador = [];
        let rotasCompanyUser = [];

        this.addRouteInList(
            rotasColaborador, 
            [
                'empresa-colaborador-list',
                'empresa-colaborador-register',
                'empresa-colaborador-show',
                'empresa-colaborador-edit',
                'empresa-colaborador-import',
                'empresa-colaborador-import-list'
            ]);

        this.addRouteInList(
            rotasCompanyUser, 
            [
                'empresa-usuario-list', 
                'empresa-usuario-register',
                'empresa-usuario-show',
                'empresa-usuario-edit'
            ]);
        
        if(rotasColaborador.includes(this.$route.path)){
            this.colaborador.variant="custom-blue"
        }

        if(rotasCompanyUser.includes(this.$route.path)){
            this.companyUser.variant="custom-blue"
        }

        this.setNavItemActive(
            [
                this.dados,
                this.contato,
                this.atendimento
            ]
        );

        this.canViewCompanyUsers = this.$can(actions.VISUALIZAR, subjects.USUARIO_EMPRESA_MENUS_ROTAS)
    },
    methods:{
        addRouteInList(array, items){
            items.filter((item) => {
            array.push(
                this.$router.resolve({ 
                name: item
                }).route.path
            );
            });
        },

        confirmAction(route) {
            this.$emit('confirmed', route);
        },

        setNavItemActive(items){
            items.filter((item) => {
                if(this.$route.name == item.path){
                    item.variant = "custom-blue"
                }
            });
        },

        verififyPermissionViewColab() {
            this.canViewColab = this.$can(actions.VISUALIZAR, subjects.COLABORADOR_MENUS_ROTAS) && this.userCanViewColab;
        },
    },

    watch: {
        empresa: {
            handler (empresa){
                this.userCanViewColab = empresa.gerenciarColaboradores;

                this.verififyPermissionViewColab();
            },
            deep:true,
        }
    }
}
</script>

<style scoped>

.posicaoIcone{
    z-index: 0;
}

.posicaoAbas{
    z-index: 1;
}
.box-img-bg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: 0% 100%;
    border-radius: 0.3rem 0.3rem 0 0;
    overflow: hidden;
}
.logo-empresa{
    height: 100px;
    width: 100px;
    background-color:#002060;
    border: 2px solid #fff;
}

.logo-empresa h1{
    font-size: 5rem;
    line-height: 4rem;
    font-style: italic;
    color: white;
    padding: 0px;
}

.comprimento_maximo_celulas{
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

@media (max-width: 755px) {
    .colunaResponsivo{
        flex-direction: column !important;
    }
}

@media (max-width: 655px) { 
    .comprimento_maximo_celulas{
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
    }
}

@media (max-width: 400px) { 
    .comprimento_maximo_celulas{
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
    }
}
</style>